import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form, Card, Row, Col } from "react-bootstrap";
import { Select } from 'antd';
// custom components
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import ImageForm from "../../../components/forms/ImageForm";
import ModalStepsLoader from "../../../components/modals/loaderStepsModal";
import SelectProductCategory from "./selectProductCategory";
import { TagsContext } from "../../../contexts/TagsContext";
// utils
import { wait } from '../../../utils/wait';
// custom hooks
import useModalWithSteps from "../../../hooks/useModalWithSteps";
// request
import filesRequests from "../../../request/files";
import miscellaneousRequest from "../../../request/miscellaneous";
import productRequests from "../../../request/products";
// icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// style
import './style/productForm.css';


function CreateProductForm() {
	const navigate = useNavigate();
	const { tagsByCategories } = useContext(TagsContext);

	const {
		modalShow,
		setModalShow,
		modalCurrentStep,
		setModalCurrentStep
	} = useModalWithSteps();

	const [errors, setErrors] = useState({ errorExists: false, errorList: [] });
	const [inputValues, setInputValues] = useState({ name: '', color: '#000000', price: 1 });
	const [imageFile, setImageFile] = useState(null);
	const [categoriesIds, setCategoriesIds] = useState([]);
	const [tags, setTags] = useState([]);
	// const [pdfSizes, setPdfSizes] = useState([]);
	const [pdfSizesQty, setPdfSizesQty] = useState(1);
	const [watermarks, setWatermarks] = useState([]);
	const [defaultWatermarkId, setDefaultWatermarkId] = useState(null);

	useEffect(() => {
		filesRequests.watermarks().then(setWatermarks);
		miscellaneousRequest.getDefaultWatermarkId().then(setDefaultWatermarkId);
    }, []);

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		setInputValues({
			...inputValues,
			[name]: value
		});
	}

	const _verifyBeforeSubmit = () => {
		let _errors = [];

		if(!inputValues.name) 			_errors.push('Product name needed');
		if(inputValues.price < 0.1)     _errors.push('Price needs to be greather than $0.1');
		if(categoriesIds.length === 0)  _errors.push('Product category needed');
		if(!imageFile) 					_errors.push('Product image needed');

		return {
			errorExists: _errors.length > 0,
			errorList: _errors
		}
	}


	const handleSubmit = async (ev) => {
		ev.preventDefault();
		const { errorExists, errorList } = _verifyBeforeSubmit();

		setErrors({ errorExists, errorList });

		if(errorExists) return;

		try {
			setModalShow(true);

			setModalCurrentStep("Adding watermarks to file");
			// 1) save image and get fileID
			const imageSaved = await filesRequests.uploadProductImage(imageFile, defaultWatermarkId);

			setModalCurrentStep("Creating product");
			// 2) create product
			const productCreated = await productRequests.createProduct({
				name: inputValues.name,
				price: inputValues.price,
				badgeColor: inputValues.color,
				categoriesIds,
				filesId: imageSaved._id,
				published: false,
				pdfSizes: [], // default 
				tags,
			});
			console.log('product created', productCreated);

			// 3) save product on woocommerce
			const wooProductId = await productRequests.wpUpsertProduct({
				woo_product_id: 0,
				customizer_product_id: productCreated._id,
				name: inputValues.name,
				price: inputValues.price,
				image_url: productCreated.filesId.pathWithWatermark,
				status: 'draft'
			});
			console.log(wooProductId, 'wooProductId')

			// 4 update product with the correct woo_product_id
			const productUpdated = await productRequests.updateProduct({
				_id: productCreated._id,
				wooProductId
			});
			console.log('product updated', productUpdated);

			// 5) final, redirect
			setModalCurrentStep('Product succesfully created, redirecting...');
			await wait(2000);

			navigate('/pages/products');

		} catch (_err) {
			setModalShow(false);
			setModalCurrentStep("");
			setErrors({ errorExists: true, errorList: [
				_err.message
			]});
		}
	}


	const handleWatermarkSelect = async (watermarkId) => {
		if (watermarkId === defaultWatermarkId) {
			return;
		}
		setDefaultWatermarkId(watermarkId);
	};

	const handleOnSelectCategory = (idsArrayMatrix) => {
		// it's a 2x2 matrix, so we want to skip the parent category if is already added
		const addedCategories = [];

		for (const arr of idsArrayMatrix) {
			for (const categoryId of arr) {
				if(!addedCategories.includes(categoryId)){
					addedCategories.push(categoryId);
				}
			}
		}
		
		const cleanedCategoriesIds = addedCategories.filter(Boolean);
		console.log(cleanedCategoriesIds);

		setCategoriesIds(cleanedCategoriesIds);
	}

	const handleAddSize = () => {
		setPdfSizesQty(pdfSizesQty + 1);
	}

	// const handlePdfSizeChange = (index, widthOrheight, value) => {
	// 	const _pdfSizes = [...pdfSizes];

	// 	if(_pdfSizes[index]){
	// 		_pdfSizes[index][widthOrheight] = Number(value);
	// 	}else{
	// 		_pdfSizes[index] = { [widthOrheight]: Number(value) }
	// 	}

	// 	setPdfSizes(_pdfSizes);
	// }


	return (
		<div>
			<ModalStepsLoader show={modalShow} currentStepText={modalCurrentStep} />

			<SimplePageTitle>
				Create new product
			</SimplePageTitle>

			<Card>
				<Card.Body>
					<Form onSubmit={handleSubmit} id="productForm">

						{
							errors.errorList.map( (errMsg, idx) => (
								<Alert className="my-3" variant="danger" key={idx}>
									<div className="alert-message">{errMsg}</div>
								</Alert>
								)
							)
						}

						<Row>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Product name</Form.Label>
									<Form.Control
										type="text"
										name="name"
										placeholder="name"
										onChange={handleChange}
										autoComplete="off"
										required
									/>
								</Form.Group>
							</Col>
						</Row>

						<Form.Group className="mb-3">
							<Form.Label>Price</Form.Label>
							<Form.Control
								type="number"
								name="price"
								defaultValue={inputValues.price}
								onChange={handleChange}
								required
							/>
						</Form.Group>

						<SelectProductCategory onSelectCategory={handleOnSelectCategory} />

						<Form.Group className="mb-3">
							<Form.Label>Product tags</Form.Label>
							<Select
								mode="multiple"
								value={tags}
								onChange={setTags}
								style={{ width: 200, display: "block" }}
							>
								{tagsByCategories.filter(item => !!item.tags.length).map(item => (
									<Select.OptGroup key={item._id} label={item.name}>
										{item.tags.map(tag => (
											<Select.Option key={tag.text} value={tag.text}>
												{tag.text}
											</Select.Option>
										))}
									</Select.OptGroup>
								))}
							</Select>
						</Form.Group>

						<ImageForm
							setImageFile={setImageFile}
							pageName="Product"
							imageUrl={imageFile}
							watermarks={watermarks}
							onWatermarkSelect={handleWatermarkSelect}
							selectedWatermark={defaultWatermarkId}
						/>


						{/* <Form.Group>
							<Form.Label>PDF sizes (inches)</Form.Label>
							{
								new Array(pdfSizesQty).fill(0).map((_, index) => (
									<div key={index} className="pdf-size-flex">
										<Form.Control
											type="number"
											placeholder="width"
											required={pdfSizes[index]?.height}
											onChange={ev => handlePdfSizeChange(index, 'width', ev.target.value)}
										/>
										<div className='mx-4'>x</div>
										<Form.Control
											type="number"
											placeholder="height"
											required={pdfSizes[index]?.width}
											onChange={ev => handlePdfSizeChange(index, 'height', ev.target.value)}
										/>
									</div>
								))
                        	}

							<div className='my-4'>
								<Button onClick={handleAddSize} type="button" size="sm" variant="success">
									<span className='mx-2'>Add Size</span>
									<FontAwesomeIcon icon={faPlus} />
								</Button>
							</div>
						</Form.Group> */}

						<Button type="submit" size="lg" variant="primary">Create Product</Button>
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
}

export default CreateProductForm;