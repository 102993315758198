import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Form, Card, Row, Col, Spinner } from "react-bootstrap";
import { Select, Switch } from 'antd';
import { toast } from 'react-toastify';
// custom components
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import ImageForm from "../../../components/forms/ImageForm";
import ModalStepsLoader from "../../../components/modals/loaderStepsModal";
import SelectProductCategory from "./selectProductCategory";
import { TagsContext } from "../../../contexts/TagsContext";
// utils
import { wait } from '../../../utils/wait';
// custom hooks
import useModalWithSteps from "../../../hooks/useModalWithSteps";
import { useIsMounted } from "../../../hooks/useIsMounted";
// request
import filesRequests from "../../../request/files";
import productRequests from "../../../request/products";
// icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
// style
import './style/productForm.css';
import 'antd/lib/switch/style/css';


function EditProductForm() {
	const navigate = useNavigate();
    const { productId } = useParams();
    const isMounted = useIsMounted();
    const { tagsByCategories } = useContext(TagsContext);

	const {
		modalShow,
		setModalShow,
		modalCurrentStep,
		setModalCurrentStep
	} = useModalWithSteps();

	const [errors, setErrors] = useState({ errorExists: false, errorList: [] });
	const [inputValues, setInputValues] = useState({ name: '', color: '#000000', price: 1, wooProductId: 0 });
	const [imageFile, setImageFile] = useState(null);
	const [categoriesIds, setCategoriesIds] = useState([]);
	const [tags, setTags] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isPublished, setIsPublished] = useState(false);
	const [watermarks, setWatermarks] = useState([]);
	// const [pdfSizes, setPdfSizes] = useState([]);
	const [productData, setProductData] = useState({});
	const [selectedWatermark, setSelectedWatermark] = useState();

    useEffect(() => {
		filesRequests.watermarks().then(setWatermarks);
        
		productRequests.getProductAsAdmin(productId)
            .then( productData => {
                if(isMounted.current){
                    const { name, price, badgeColor, tags, categoriesIds, filesId, published, wooProductId, pdfSizes } = productData;
                    setInputValues({ name, price, wooProductId, color: badgeColor });
                    setCategoriesIds(categoriesIds.length ? categoriesIds.map( c => c._id) : []);
                    setImageFile(filesId.pathWithWatermark);
					setIsPublished(published);
					setTags(tags);
					// setPdfSizes(pdfSizes || []);
					setProductData(productData);
					setSelectedWatermark(filesId.watermarkId || "default");
                }
            })
            .finally(() => {
                if(isMounted.current){
                    setIsLoading(false);
                }
            });
    }, []);

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		setInputValues({
			...inputValues,
			[name]: value
		});
	}

	const _verifyBeforeSubmit = () => {
		let _errors = [];

		if(!inputValues.name) 			_errors.push('Product name needed');
		// if(!inputValues.color) 			_errors.push('Color badge needed');
		if(inputValues.price < 0.1)     _errors.push('Price needs to be greather than $0.1');
		if(categoriesIds.length === 0)  _errors.push('Product category needed');
		if(!imageFile) 					_errors.push('Product image needed');

		return {
			errorExists: _errors.length > 0,
			errorList: _errors
		}
	}                     


	const handleSubmit = async (ev) => {
        ev.preventDefault();
		const { errorExists, errorList } = _verifyBeforeSubmit();

		setErrors({ errorExists, errorList });

		if(errorExists) return;

        console.log(inputValues);

		try {
			setModalShow(true);

			// 1) save image if there is a new one
            let imageSaved;
            if(!(typeof imageFile === 'string')){
                setModalCurrentStep("Adding watermarks to new image");
                imageSaved = await filesRequests.uploadProductImage(imageFile, selectedWatermark);
            }

			// 2) update product
            setModalCurrentStep("Updating");

            const bodyToUpdate = {
                _id: productId,
                name: inputValues.name,
                price: inputValues.price,
                badgeColor: inputValues.color,
                published: isPublished,
				// pdfSizes: pdfSizes.filter( s => s), // truthy values
				pdfSizes: [], // default
                categoriesIds,
                tags,
            };

            if(imageSaved){
                bodyToUpdate.filesId = imageSaved._id;
            }

			const productUpdated = await productRequests.updateProduct(bodyToUpdate);
            console.log('p updated: ', productUpdated);

			// 4) update in wordpress
			const wpUpdatedProductId = await productRequests.wpUpsertProduct({
				woo_product_id: inputValues.wooProductId,
				customizer_product_id: productUpdated._id,
				name: inputValues.name,
				price: inputValues.price,
				status: isPublished ? 'publish' : 'draft',
				image_url: imageSaved ? productUpdated.filesId.pathWithWatermark : ''
			});

			// 5) final, redirect
			setModalCurrentStep('Product succesfully updated, redirecting...');
			await wait(2000);

			navigate('/pages/products');

		} catch (_err) {
			setModalShow(false);
			setModalCurrentStep("");
			setErrors({ errorExists: true, errorList: [
				_err.message
			]});
		}
	}

	const handleWatermarkSelect = async (watermarkId) => {
		if (watermarkId === selectedWatermark) {
			return;
		}
		setModalShow(true);
		setModalCurrentStep("Changing watermarks of the image");
		const result = await filesRequests.changeWatermark(
			productData.filesId._id,
			watermarkId
		);

		console.log(result, 'result')
		setImageFile(result.pathWithWatermark);
		setSelectedWatermark(watermarkId);
		setModalShow(false);
		setModalCurrentStep("");
		toast.success("Watermark Changed", { position: "bottom-center" });
	};

	const handleWatermarkUpload = async (file) => {
		setModalShow(true);
		setModalCurrentStep("Uploading new watermark");
		const newWatermark = await filesRequests.saveWatermark(file);
		setWatermarks([...watermarks, newWatermark]);
		setModalCurrentStep("Changing watermarks of the image");
		await handleWatermarkSelect(newWatermark._id);
		setModalShow(false);
		setModalCurrentStep("");
	}

	const handleOnSelectCategory = (idsArrayMatrix) => {
		// it's a 2x2 matrix, so we want to skip the parent category if is already added
		const addedCategories = [];

		for (const arr of idsArrayMatrix) {
			for (const categoryId of arr) {
				if(!addedCategories.includes(categoryId)){
					addedCategories.push(categoryId);
				}
			}
		}
		const cleanedCategoriesIds = addedCategories.filter(Boolean);
		console.log("cleanedCategoriesIds", cleanedCategoriesIds);

		setCategoriesIds(cleanedCategoriesIds);
	}

	
	// const handleAddSize = () => {
	// 	setPdfSizes([...pdfSizes, null]);
	// }

	// const handlePdfSizeChange = (index, widthOrheight, value) => {
	// 	const _pdfSizes = [...pdfSizes];

	// 	if(_pdfSizes[index]){
	// 		_pdfSizes[index][widthOrheight] = Number(value);
	// 	}else{
	// 		_pdfSizes[index] = { [widthOrheight]: Number(value) }
	// 	}

	// 	setPdfSizes(_pdfSizes);
	// }

	// const handleDeletePdfSize = index => {
	// 	console.log(index);
	// 	const _pdfSizes = [...pdfSizes];
	// 	_pdfSizes.splice(index, 1);
	// 	console.log(JSON.stringify(pdfSizes));
	// 	console.log(JSON.stringify(_pdfSizes));
	// 	setPdfSizes(() => _pdfSizes);
	// }


    if(isLoading) return <Spinner animation="border" />

	return (
		<div>
			<ModalStepsLoader show={modalShow} currentStepText={modalCurrentStep} />

			<SimplePageTitle>
				Edit product
			</SimplePageTitle>

			<Card>
				<Card.Body>
					<Form onSubmit={handleSubmit} id="productForm">
						{
							errors.errorList.map( (errMsg, idx) => (
								<Alert className="my-3" variant="danger" key={idx}>
									<div className="alert-message">{errMsg}</div>
								</Alert>
								)
							)
						}

						<Form.Group className="mb-3">
							<div>Published</div>
							<Switch checked={isPublished} onChange={setIsPublished} />
						</Form.Group>

						<Row>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Product name</Form.Label>
									<Form.Control
										type="text"
										name="name"
										placeholder="name"
										onChange={handleChange}
										autoComplete="off"
										value={inputValues.name}
										required
									/>
								</Form.Group>
							</Col>
						</Row>

						<Form.Group className="mb-3">
							<Form.Label>Price</Form.Label>
							<Form.Control
								type="number"
								name="price"
								defaultValue={inputValues.price}
								onChange={handleChange}
								step="any"
								required
							/>
						</Form.Group>

						<SelectProductCategory
								onSelectCategory={handleOnSelectCategory}
								defaultValues={categoriesIds}
						/>

						<Form.Group className="mb-3">
							<Form.Label>Product tags</Form.Label>
							<Select
								mode="multiple"
								value={tags}
								onChange={setTags}
								style={{ width: 200, display: "block" }}
							>
								{tagsByCategories.filter(item => !!item.tags.length).map(item => (
									<Select.OptGroup key={item._id} label={item.name}>
										{item.tags.map(tag => (
											<Select.Option key={tag.text} value={tag.text}>
												{tag.text}
											</Select.Option>
										))}
									</Select.OptGroup>
								))}
							</Select>
						</Form.Group>

						{/* <Form.Group>
							<Form.Label>PDF sizes (inches)</Form.Label>
							{
								pdfSizes.map((sizeObj, index) => (
									<div key={index} className="pdf-size-flex">
										<Form.Control
											type="number"
											placeholder="width"
											required={sizeObj?.height}
											value={sizeObj?.width || ''}
											onChange={ev => handlePdfSizeChange(index, 'width', ev.target.value)}
										/>
										<div className='mx-4'>x</div>
										<Form.Control
											type="number"
											placeholder="height"
											required={sizeObj?.width}
											value={sizeObj?.height || ''}
											onChange={ev => handlePdfSizeChange(index, 'height', ev.target.value)}
										/>
										<div className='mx-4'>
											<Button onClick={() => handleDeletePdfSize(index)} type="button" size="sm" variant="danger" className="rounded-circle">
												<FontAwesomeIcon icon={faTrash} />
											</Button>
										</div>
									</div>
								))
                        	}

							<div className='my-4'>
								<Button onClick={handleAddSize} type="button" size="sm" variant="success">
									<span className='mx-2'>Add Size</span>
									<FontAwesomeIcon icon={faPlus} />
								</Button>
							</div>
						</Form.Group> */}

						<ImageForm
							setImageFile={setImageFile}
							pageName="Product"
							imageUrl={imageFile}
							watermarks={watermarks}
							onWatermarkSelect={handleWatermarkSelect}
							selectedWatermark={selectedWatermark}
						/>

						<Button className="mt-3" type="submit" size="lg">
							Update Product
						</Button>
						{typeof imageFile === "string" && (
							<>
								<input
									id="watermark-upload"
									type="file"
									hidden
									onChange={(e) => {
										handleWatermarkUpload(e.target.files[0]);
										e.target.value = null;
									}}
								/>
								<label
									htmlFor="watermark-upload"
									className="btn btn-success btn-lg mt-3 ms-2"
								>
									Add a custom watermark
								</label>
							</>
						)}
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
}

export default EditProductForm;