/**
 * change this every time you will deploy on development or production
 */

const IS_DEVELOPMENT = false;
let API_BASE_URL, WP_API_BASE_URL;

if (IS_DEVELOPMENT) {
    // API_BASE_URL = 'https://hazmoonalive.xyz/api' // dev backend
    WP_API_BASE_URL = 'https://hazmoona.webarysites.com/api/customizer'; // dev wordpress

    API_BASE_URL = 'http://localhost:7000/api'; // development
}
else {
    API_BASE_URL = 'https://hazmoonapi.xyz/api' // dev backend
    WP_API_BASE_URL = 'https://hazmoona.com/api/customizer'; // dev wordpress
}

export {
    API_BASE_URL,
    WP_API_BASE_URL
}
